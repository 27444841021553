import * as React from "react";

const SVGPatternedSidebar = (props) => (
	<svg
		width={199}
		height={400}
		viewBox="0 0 199 400"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_251_715)">
			<path
				d="M190 -37.0837C193.573 -37.0837 196.874 -35.1777 198.66 -32.0837L229.803 21.8572C231.589 24.9512 231.589 28.7632 229.803 31.8572L198.66 85.7981C196.874 88.8921 193.573 90.7981 190 90.7981L127.714 90.7981C124.142 90.7981 120.84 88.8922 119.054 85.7981L87.9113 31.8572C86.1249 28.7632 86.1249 24.9512 87.9113 21.8572L119.054 -32.0837C120.84 -35.1777 124.142 -37.0837 127.714 -37.0837L190 -37.0837Z"
				fill="#EEEEEE"
				fillOpacity={0.3}
			/>
			<path
				d="M132 34.9163C135.573 34.9163 138.874 36.8223 140.66 39.9163L171.803 93.8572C173.589 96.9512 173.589 100.763 171.803 103.857L140.66 157.798C138.874 160.892 135.573 162.798 132 162.798L69.7143 162.798C66.1417 162.798 62.8404 160.892 61.0541 157.798L29.9113 103.857C28.1249 100.763 28.1249 96.9512 29.9113 93.8572L61.0541 39.9163C62.8404 36.8223 66.1417 34.9163 69.7143 34.9163L132 34.9163Z"
				fill="#EEEEEE"
				fillOpacity={0.3}
			/>
			<path
				d="M68.9999 123.916C72.5726 123.916 75.8739 125.822 77.6602 128.916L108.803 182.857C110.589 185.951 110.589 189.763 108.803 192.857L77.6602 246.798C75.8739 249.892 72.5726 251.798 68.9999 251.798L6.71434 251.798C3.14168 251.798 -0.159603 249.892 -1.94593 246.798L-33.0887 192.857C-34.8751 189.763 -34.8751 185.951 -33.0887 182.857L-1.94593 128.916C-0.159605 125.822 3.14167 123.916 6.71433 123.916L68.9999 123.916Z"
				fill="#EEEEEE"
				fillOpacity={0.3}
			/>
			<path
				d="M116 215.916C119.573 215.916 122.874 217.822 124.66 220.916L155.803 274.857C157.589 277.951 157.589 281.763 155.803 284.857L124.66 338.798C122.874 341.892 119.573 343.798 116 343.798L53.7143 343.798C50.1417 343.798 46.8404 341.892 45.0541 338.798L13.9113 284.857C12.1249 281.763 12.1249 277.951 13.9113 274.857L45.0541 220.916C46.8404 217.822 50.1417 215.916 53.7143 215.916L116 215.916Z"
				fill="#EEEEEE"
				fillOpacity={0.3}
			/>
			<path
				d="M163 308.916C166.573 308.916 169.874 310.822 171.66 313.916L202.803 367.857C204.589 370.951 204.589 374.763 202.803 377.857L171.66 431.798C169.874 434.892 166.573 436.798 163 436.798L100.714 436.798C97.1417 436.798 93.8404 434.892 92.0541 431.798L60.9113 377.857C59.1249 374.763 59.1249 370.951 60.9113 367.857L92.0541 313.916C93.8404 310.822 97.1417 308.916 100.714 308.916L163 308.916Z"
				fill="#EEEEEE"
				fillOpacity={0.3}
			/>
		</g>
		<defs>
			<clipPath id="clip0_251_715">
				<rect width={199} height={400} fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default SVGPatternedSidebar;
