import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGDottedPattern from "./SVG/dotted-pattern";
import SVGDottedPatternBlack from "./SVG/dotted-pattern-black";
import SVGPolygon from "./SVG/polygon";

const HowCanWeHelpLeft = ({
	heading,
	text,
	img,
	height,
	imgAlt,
	width,
	list,
	listHeading,
}) => {
	const newListHeading = listHeading || "We can help you by:";
	const newHeight = height || "30rem";
	const newWidth = width || "30rem";
	return (
		<section className="my-6 ">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} lg={6} className="mb-4 mb-md-0 d-none d-xl-block">
						<div
							className="position-relative mx-auto "
							style={{ height: newHeight, width: newWidth }}
						>
							<GatsbyImage
								className="position-absolute"
								style={{ height: newHeight, width: newWidth }}
								alt={imgAlt}
								image={img}
							/>{" "}
							<SVGDottedPattern
								style={{ left: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
								className="position-absolute  d-none d-lg-block "
							/>
						</div>
					</Col>
					<Col
						className="px-xl-5 text-md-center text-lg-start pt-lg-5  pt-md-3 pt-xl-0"
						xs={12}
						xl={6}
					>
						<div className="position-relative">
							<SVGPolygon
								className="position-absolute d-none d-lg-block"
								style={{ zIndex: -1, left: "-1rem", top: "-2.5rem" }}
							/>
							<h2 className=" text-start  mb-3">{heading}</h2>
						</div>
						<hr className="hr-primary" />

						<p className="text-start">{text}</p>
						<div xs={12} className="d-xl-none mt-4">
							<GatsbyImage className="mb-4" alt={imgAlt} image={img} />
						</div>
						<div
							style={{
								background:
									"linear-gradient(102.21deg, #fbba00 45.22%, rgba(251, 180, 23, 0.68) 98.07%)",
							}}
							className="mt-xl-5 mt-3 p-4 pe-6  d-inline-block position-relative"
						>
							<div>
								<h4>{newListHeading}</h4>
							</div>
							<div
								style={{
									width: "25rem",
									boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
								}}
								className="position-absolute start-20 top-80 bg-white pt-4 p-3 d-lg-block d-none"
							>
								<div className="w-100 h-100 position relative">
									{list}{" "}
									<SVGDottedPatternBlack
										style={{ right: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
										className="position-absolute d-none d-lg-block  "
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
							}}
							className="w-100 h-100 p-3 mt-4 position relative d-lg-none"
						>
							{list}{" "}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default HowCanWeHelpLeft;
