import * as React from "react";

const SVGLcdBgPoly1 = (props) => (
	<svg
		width={229}
		height={535}
		viewBox="0 0 229 535"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M456.043 0.28955C459.616 0.289557 462.917 2.19553 464.703 5.28955L613.219 262.527C615.006 265.621 615.006 269.433 613.219 272.527L464.703 529.764C462.917 532.858 459.616 534.764 456.043 534.764L159.011 534.764C155.438 534.764 152.137 532.858 150.351 529.764L1.83455 272.527C0.0482109 269.433 0.0482127 265.621 1.83454 262.527L150.351 5.28955C152.137 2.19554 155.438 0.289557 159.011 0.289553L456.043 0.28955Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGLcdBgPoly1;
